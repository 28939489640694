//////////////////////
// THEME / APP / GoURL
//////////////////////


// Condensed buttons (for modals and GoURL responsive table)
.unmc .go_responsive_table .dcf-btn,
.unmc .dcf-btn-close-modal {
  padding: .5rem .75rem;
}


// Restore browser default focus styles for inputs
.unmc .dcf-input-text:focus,
.unmc .dcf-form input:not([type="submit"]):focus,
.unmc .dcf-form textarea:focus {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}
